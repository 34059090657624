import { useLobbyGameApi } from '@/api/lobby-game'
import { useAppStore } from '@/store/app'

export const useJackpot = () => {
  const { $axios } = useNuxtApp()
  const { setJackpot, setSumJackpot } = useAppStore()
  const { JACKPOT, SUM_JACKPOT } = useLobbyGameApi()
  const jackpotInterval = ref<ReturnType<typeof setInterval> | null>(null)
  const sumJackpotInterval = ref<ReturnType<typeof setInterval> | null>(null)
  const JACKPOT_INTERVAL = useRuntimeConfig().JACKPOT_INTERVAL

  const fetchJackpot = async () => {
    try {
      const response = await $axios.get(JACKPOT)
      if (response && response.data?.status === 'OK') {
        setJackpot(response.data.data)
      }
    } catch (error: any) {
      console.log('error', error?.message || error)
    }
  }

  const fetchSumJackpot = async () => {
    try {
      const response = await $axios.get(SUM_JACKPOT)
      if (response && response.data?.status === 'OK') {
        setSumJackpot(response.data.data)
      }
    } catch (error: any) {
      console.log('error', error?.message || error)
    }
  }

  const onJackpotIntervalClear = () => {
    if (jackpotInterval.value) {
      clearInterval(jackpotInterval.value)
      jackpotInterval.value = null
    }
  }

  const onJackpotInterval = () => {
    fetchJackpot()
    onJackpotIntervalClear()
    jackpotInterval.value = setInterval(fetchJackpot, JACKPOT_INTERVAL)
  }

  const onSumJackpotIntervalClear = () => {
    if (sumJackpotInterval.value) {
      clearInterval(sumJackpotInterval.value)
      sumJackpotInterval.value = null
    }
  }

  const onSumJackpotInterval = () => {
    fetchSumJackpot()
    onSumJackpotIntervalClear()
    sumJackpotInterval.value = setInterval(fetchSumJackpot, JACKPOT_INTERVAL)
  }

  return {
    fetchJackpot,
    fetchSumJackpot,
    onJackpotInterval,
    onJackpotIntervalClear,
    onSumJackpotIntervalClear,
    onSumJackpotInterval
  }
}
